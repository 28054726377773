/**
 * @deprecated
 *
 * @info  Only for api v1
 *
 * TODO delete after create dedicated classes for each type of app layer (api, logic, ui etc)
 * TODO avoid totally global exception service
 */
export default class Exception {
  public error: any;
  public validations: any[];

  constructor(error: any, validations: any = []) {
    this.error = error;
    this.validations = validations;
  }
}
